const init = () => {
    const body = document.body
    const hamburger = document.getElementById('hamburger') as HTMLButtonElement
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('is-active')
        if (body.getAttribute('data-menu') === 'true') {
            body.setAttribute('data-menu', 'false')
        } else {
            body.setAttribute('data-menu', 'true')
        }
    })

    const copyright = document.querySelectorAll('[data-copyright]')
    copyright.forEach(el => {
        el.innerHTML = env.COPYRIGHT
    })
}
init()

export const processContent = (content: string) => {
    console.log('Yeah', content)
    // documentToHtmlString(JSON.parse(content))
}
